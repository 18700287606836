import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from 'src/apiRequest';
import InspectorListComponent from 'src/components/SchedulePage/Timeline/InspectorList/InspectorListComponent';
import { setInitialFilteredInspectors, setInspectors, setInspectorsAvailabilities } from 'src/store/ducks/schedule';

const InspectorListContainer = () => {
  const [busy, setBusy] = useState(true);
  const inspectors = useSelector(({ schedule }) => schedule.filteredInspectors);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInspectors = async () => {
      try {
        const { data } = await API.get('/assignables');

        dispatch(setInitialFilteredInspectors(data));
        dispatch(setInspectors(data));
        dispatch(setInspectorsAvailabilities());
      } catch (err) {
        console.log(err);
      } finally {
        setBusy(false);
      }
    };
    fetchInspectors();
  }, [dispatch]);

  return <InspectorListComponent busy={busy} inspectors={inspectors} />;
};

export default InspectorListContainer;
