import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import orderCardIcon from 'src/components/SchedulePage/OrderCard/orderCardIcon';
import classes from 'src/components/SchedulePage/OrderCard/style.module.scss';
import getIcon from 'src/helpers/order-icon';
import iconOrderDelayed15 from 'src/images/icons/orderDelayed15.svg';
import iconOrderDelayed30 from 'src/images/icons/orderDelayed30.svg';

const orderStatusToShow = {
  WAITING: {
    background: '#ffffff',
    border: '#005f98',
  },
  ACCEPTED: {
    background: '#ffffff',
    border: '#005f98',
  },
  ASSIGNED: {
    background: '#0170b3',
    border: '#005f98',
  },
  FINALIZED_BY_DEVICE: {
    background: '#ffffff',
    border: '#3DC032',
  },
  FINISHED: {
    background: '#74ff68',
    border: '#3DC032',
  },
  IN_PROGRESS: {
    background: '#F2DE28',
    border: '#F2C628',
  },
  VNR: {
    background: '#ffffff',
    border: '#C03030',
  },
  REJECTED: {
    background: '#F74343',
    border: '#C03030',
  },
  CANCELED: {
    background: '#F74343',
    border: '#C03030',
  },
};

const OrderCardComponent = ({
  intl,
  order,
  schedule,
  setSelectedOrder,
  insideTimelineStyle,
  onDragStart,
  onDragEnd,
  onDragEndCapture,
  isAlreadyBeingEditedByAnotherUser,
  inTimeline,
  lockIconState,
  keyIconState,
  formattedAllowedAtDate,
  formattedCreatedAtDate,
  formattedOrderAddress,
  forwardedRef,
}) => {
  const [orderDelayed30, setOrderDelayed30] = useState(false);
  const [orderDelayed15, setOrderDelayed15] = useState(false);
  const [hasOrderActions, setHasOrderActions] = useState(false);

  const renderDelayedIcon = (icon, title, threshold) => (
    <Tooltip
      title={`Começa em menos de ${threshold} minutos. Ainda não iniciada pelo vistoriador.`}
      placement="bottom-start"
      classes={{ tooltip: classes.orderCardTooltip }}
    >
      <img className={classes.iconDelayed} src={icon} alt={title} />
    </Tooltip>
  );

  useEffect(() => {
    const checkTime30 = time => {
      const timeNow = moment();
      const timeMoment = moment(time, 'HH:mm');
      const minutesDiff = timeMoment.diff(timeNow, 'minutes');
      return minutesDiff <= 30;
    };
    const checkTime15 = time => {
      const timeNow = moment();
      const timeMoment = moment(time, 'HH:mm');
      const minutesDiff = timeMoment.diff(timeNow, 'minutes');
      return minutesDiff <= 15;
    };

    const accepted = ['IN_PROGRESS', 'ACCEPTED', 'ASSIGNED'];

    const dateNow = moment().format('YYYY-MM-DD');

    const checkOrder = async () => {
      if (inTimeline && order.status !== 'FINISHED') {
        if (Math.random() < 0.2) {
          //1/5
          const { data: dataStatus } = await API.get(`/orders/${order.public_id}/status`);
          order.status = dataStatus.status;
        }

        if (
          accepted.includes(order.status) &&
          schedule?.flextime !== true &&
          checkTime30(schedule?.when?.start_time) &&
          !hasOrderActions
        ) {
          const { data } = await API.get(
            `/activity-log/orders/${order.public_id}/actions/exist?types[]=INSPECTOR_CHECKIN&types[]=INSPECTOR_DO_INSPECTION_NOW`
          );

          if (data?.exists) {
            setHasOrderActions(true);
            setOrderDelayed30(false);
            setOrderDelayed15(false);
          } else {
            const isDelayed15 = checkTime15(schedule?.when?.start_time);
            setOrderDelayed30(!isDelayed15);
            setOrderDelayed15(isDelayed15);
          }
        } else {
          setOrderDelayed30(false);
          setOrderDelayed15(false);
        }
      } else {
        setOrderDelayed30(false);
        setOrderDelayed15(false);
      }
    };

    if (dateNow === schedule?.when?.date) {
      checkOrder();

      const intervalId = setInterval(() => {
        checkOrder();
      }, 90000);
      return () => clearInterval(intervalId);
    }
  }, [inTimeline, order, hasOrderActions, setHasOrderActions]);

  return (
    <button
      type="button"
      className={`
      ${classes.orderCard}
      ${inTimeline && classes.inTimeline}
      ${orderDelayed15 && classes.orderDelayed15}
      ${orderDelayed30 && classes.orderDelayed30}
      ${isAlreadyBeingEditedByAnotherUser && classes.isEditingThisOrder}
    `}
      style={insideTimelineStyle}
      onClick={() => setSelectedOrder({ orderId: order?.id || order?.public_id })}
      onDragEndCapture={onDragEndCapture}
      onDragStart={evt => onDragStart({ order, schedule }, evt)}
      onDragEnd={onDragEnd}
      ref={forwardedRef}
      draggable
    >
      {orderDelayed15 && renderDelayedIcon(iconOrderDelayed15, intl.formatMessage({ id: 'iconOrderDelayed15' }), 15)}
      {orderDelayed30 && renderDelayedIcon(iconOrderDelayed30, intl.formatMessage({ id: 'iconOrderDelayed30' }), 30)}

      <div className={`${classes.line} ${classes.titleLine} ${inTimeline && classes.inTimeline}`}>
        <Tooltip title={intl.formatMessage({ id: order?.type })} placement="bottom-end" classes={{ tooltip: classes.orderCardTooltip }}>
          <div className={classes.icon}>
            <img
              src={getIcon(order?.type)}
              alt={intl.formatMessage({ id: order?.type })}
              title={intl.formatMessage({ id: order?.type })}
            />
          </div>
        </Tooltip>

        <h4 className={classes.orderIdentifier} title={order?.identifier}>
          {order?.identifier}
        </h4>

        {!inTimeline && order?.credits && (
          <Tooltip
            title={intl.formatMessage({ id: 'Financial.credits' })}
            placement="right"
            classes={{ tooltip: classes.orderCardTooltip }}
          >
            <span className={classes.orderFootage}>
              {((order?.credits || 0) / 100).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </Tooltip>
        )}
      </div>

      {!inTimeline ? (
        <>
          <div className={`${classes.line} ${classes.titleLine}`}>
            <div className={classes.orderCode}>
              <p>{order?.code}</p>

              <div className={classes.separator}>&#x2022;</div>

              <p>{order?.building_type}</p>
            </div>
          </div>

          <div className={`${classes.line} ${classes.titleLine}`}>
            <p className={`${classes.orderName} ${classes.truncate}`}>{order?.client}</p>
          </div>

          <Tooltip
            title={formattedOrderAddress}
            placement="bottom-start"
            classes={{ tooltip: classes.orderCardTooltip }}
          >
            <div className={classes.line}>
              <div className={classes.orderAddress}>
                <p className={`${classes.neighborhoodName} ${classes.truncate}`}>{order?.neighborhood} </p>

                <div className={classes.separator}>&#x2022;</div>

                <p className={`${classes.city} ${classes.truncate}`}>{order?.city}</p>
              </div>
            </div>
          </Tooltip>
        </>
      ) : (
        <>
          <div className={`${classes.line} ${classes.titleLine}`}>
            <p className={`${classes.orderName} ${classes.truncate}`}>{order?.client}</p>
          </div>

          <div className={`${classes.line} ${classes.titleLine}`}>
            <div className={`${classes.orderCode} ${classes.truncate}`}>
              <p>{order?.code}</p>

              <div className={classes.separator}>&#x2022;</div>

              <p>
                {((order?.credits || 0) / 100).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>

          <Tooltip
            title={formattedOrderAddress}
            placement="bottom-start"
            classes={{ tooltip: classes.orderCardTooltip }}
          >
            <div className={classes.line}>
              <div className={`${classes.orderAddress} ${classes.truncate}`}>
                <p className={`${classes.neighborhoodName} ${classes.truncate}`}>{order?.neighborhood}</p>
              </div>
            </div>
          </Tooltip>

          {order?.status in orderStatusToShow && (
            <div className={classes.line}>
              <div className={classes.orderStatus}>
                <div
                  className={classes.statusDot}
                  style={{
                    background: orderStatusToShow[order?.status].background,
                    borderColor: orderStatusToShow[order?.status].border,
                  }}
                />

                <p className={classes.statusName}>{intl.formatMessage({ id: order?.status })}</p>
              </div>
            </div>
          )}
        </>
      )}

      {!inTimeline && (
        <div className={classes.line}>
          <div className={classes.orderIcons}>
            {lockIconState === null ? (
              <Tooltip
                title={intl.formatMessage({ id: 'NO_RELEASE_DATE_SPECIFIED' })}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('unlocked')} alt="Lock icon" />
              </Tooltip>
            ) : lockIconState ? (
              <Tooltip
                title={`${intl.formatMessage({ id: 'RELEASED_ON' })} ${formattedAllowedAtDate || ''}`}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('unlocked')} alt="Lock icon" />
              </Tooltip>
            ) : (
              <Tooltip
                title={`${intl.formatMessage({ id: 'RELEASES_IN' })} ${formattedAllowedAtDate || ''}`}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('locked')} alt="Lock icon" />
              </Tooltip>
            )}

            {order?.furnished && (
              <Tooltip
                title={intl.formatMessage({ id: order?.furnished })}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon(order?.furnished)} alt="House icon" />
              </Tooltip>
            )}

            {!!order?.accompanied_inspection && (
              <Tooltip
                title={intl.formatMessage({
                  id: order?.accompanied_inspection?.isAccompanied
                    ? 'ACCOMPANIED_INSPECTION'
                    : 'NON_ACCOMPANIED_INSPECTION',
                })}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img
                  src={
                    order?.accompanied_inspection?.isAccompanied
                      ? orderCardIcon('people')
                      : orderCardIcon('people-disabled')
                  }
                  alt="People icon"
                />
              </Tooltip>
            )}

            {order?.modality === 'premium' ? (
              <Tooltip
                title={intl.formatMessage({ id: 'PREMIUM_SCHEDULE_INSPECTION' })}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('star')} alt="Star icon" />
              </Tooltip>
            ) : (
              <img src={orderCardIcon('star-disabled')} alt="Star icon" />
            )}

            {order?.urgency === 'express' ? (
              <Tooltip
                title={intl.formatMessage({ id: 'EXPRESS_SCHEDULE_INSPECTION' })}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('lightning')} alt="Lightning icon" />
              </Tooltip>
            ) : (
              <img src={orderCardIcon('lightning-disabled')} alt="Lightning icon" />
            )}

            {keyIconState === 'ACCESS_INFORMATION_LOCAL_KEYS_TYPE' ? (
              <Tooltip
                title={intl.formatMessage({ id: `SCHEDULE.${order?.access_information?.type}` })}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('key')} alt="Key icon" />
              </Tooltip>
            ) : keyIconState === 'PROPERTY_ACCESS_LOCAL_KEYS_TYPE' ? (
              <Tooltip
                title={intl.formatMessage({ id: `SCHEDULE.${order?.property_access?.type}` })}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('key')} alt="Key icon" />
              </Tooltip>
            ) : keyIconState === 'ACCESS_INFORMATION_REAL_ESTATE_KEYS_TYPE' ? (
              <Tooltip
                title={intl.formatMessage(
                  { id: `SCHEDULE.${order?.access_information?.type}` },
                  { neighborhood: order?.access_information?.address?.neighborhood }
                )}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('key')} alt="Key icon" />
              </Tooltip>
            ) : keyIconState === 'PROPERTY_ACCESS_REAL_ESTATE_KEYS_TYPE' ? (
              <Tooltip
                title={intl.formatMessage(
                  { id: `SCHEDULE.${order?.property_access?.type}` },
                  { neighborhood: order?.property_access?.address?.neighborhood }
                )}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('key')} alt="Key icon" />
              </Tooltip>
            ) : keyIconState === 'ACCESS_INFORMATION_OTHER_KEYS_TYPE' ? (
              <Tooltip
                title={order?.access_information?.address?.neighborhood}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('key')} alt="Key icon" />
              </Tooltip>
            ) : keyIconState === 'PROPERTY_ACCESS_OTHER_KEYS_TYPE' ? (
              <Tooltip
                title={order?.property_access?.address?.neighborhood}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('key')} alt="Key icon" />
              </Tooltip>
            ) : keyIconState === 'ACCESS_INFORMATION_KEYS_LOCATION_KEYS_TYPE' ? (
              <Tooltip
                title={order?.access_information?.keysLocation}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('key')} alt="Key icon" />
              </Tooltip>
            ) : keyIconState === 'PROPERTY_ACCESS_DETAILS_KEYS_TYPE' ? (
              <Tooltip
                title={order?.property_access?.keysLocation}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('key')} alt="Key icon" />
              </Tooltip>
            ) : (
              <img src={orderCardIcon('key')} alt="Key icon" />
            )}

            {order?.details || order?.access_information?.instructions ? (
              <Tooltip
                title={intl.formatMessage({
                  id: order?.details ? 'CHECK_ORDER_DETAILS' : 'CHECK_ORDER_ACCESS_INFORMATION',
                })}
                placement="bottom"
                classes={{ tooltip: classes.orderCardTooltip }}
              >
                <img src={orderCardIcon('warning')} alt="Warning icon" />
              </Tooltip>
            ) : (
              <img src={orderCardIcon('warning-disabled')} alt="Warning icon" />
            )}
          </div>

          <span className={classes.orderDate}>{formattedCreatedAtDate}</span>
        </div>
      )}
    </button>
  );
};

OrderCardComponent.propTypes = {
  order: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired,
  setSelectedOrder: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  insideTimelineStyle: PropTypes.object,
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onDragEndCapture: PropTypes.func.isRequired,
  isAlreadyBeingEditedByAnotherUser: PropTypes.bool.isRequired,
  inTimeline: PropTypes.bool,
  lockIconState: PropTypes.bool,
  keyIconState: PropTypes.oneOf([
    'ACCESS_INFORMATION_LOCAL_KEYS_TYPE',
    'PROPERTY_ACCESS_LOCAL_KEYS_TYPE',
    'ACCESS_INFORMATION_REAL_ESTATE_KEYS_TYPE',
    'PROPERTY_ACCESS_REAL_ESTATE_KEYS_TYPE',
    'ACCESS_INFORMATION_OTHER_KEYS_TYPE',
    'PROPERTY_ACCESS_OTHER_KEYS_TYPE',
    'ACCESS_INFORMATION_KEYS_LOCATION_KEYS_TYPE',
    'PROPERTY_ACCESS_DETAILS_KEYS_TYPE',
    false,
  ]),
  formattedAllowedAtDate: PropTypes.string.isRequired,
  formattedCreatedAtDate: PropTypes.string.isRequired,
  formattedOrderAddress: PropTypes.string.isRequired,
  forwardedRef: PropTypes.object.isRequired,
};

OrderCardComponent.defaultProps = {
  insideTimelineStyle: null,
  inTimeline: false,
  lockIconState: false,
  keyIconState: false,
};

export default injectIntl(OrderCardComponent);
