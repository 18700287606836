function generateInspectorAvailabilityIntervals() {
  const halfAnHourIntervals = [];

  for (let hour = 5; hour < 20; hour++) {
    const hourDate = new Date('1970-01-02');
    const halfAnHourDate = new Date('1970-01-02');

    hourDate.setHours(hour, 0, 0, 0);
    halfAnHourIntervals.push(hourDate);

    halfAnHourDate.setHours(hour, 30, 0, 0);
    halfAnHourIntervals.push(halfAnHourDate);
  }

  return halfAnHourIntervals;
}

export default generateInspectorAvailabilityIntervals();
