import React from 'react';

import PropTypes from 'prop-types';

import Spinner from 'src/components/common/Spinner';
import OrderCard from 'src/components/SchedulePage/OrderCard';
import hours from 'src/components/SchedulePage/Timeline/Content/constants/hours';
import inspectorAvailabilityIntervals from 'src/components/SchedulePage/Timeline/Content/constants/inspectorAvailabilityIntervals';
import classes from 'src/components/SchedulePage/Timeline/Content/style.module.scss';
import CurrentTime from 'src/components/SchedulePage/Timeline/CurrentTime';

const ContentComponent = ({
  busy,
  elementRef,
  orderRef,
  setHoursRef,
  inspectors,
  orders,
  getOffsetByTime,
  selectedDateIsNotToday,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
  handleCompareAvailabilityInterval,
}) => {
  if (busy || !inspectors) {
    return (
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={classes.content} ref={elementRef}>
      <CurrentTime getOffsetByTime={getOffsetByTime} selectedDateIsNotToday={selectedDateIsNotToday} />

      <div className={classes.hours} ref={setHoursRef}>
        {hours.map(hour => (
          <div className={classes.time} key={hour}>
            {' '}
            <div>{hour}</div>
          </div>
        ))}
      </div>

      <div className={classes.orderList}>
        {inspectors &&
          inspectors.map(inspector => (
            <div className={classes.line} key={inspector.public_id}>
              <div
                className={`timeline-track ${classes.track}`}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={evt => onDrop(inspector.public_id, evt)}
              >
                {Array.isArray(inspector.todayAvailabilities) &&
                  !!inspector.todayAvailabilities.length &&
                  inspectorAvailabilityIntervals.map(halfAnHourInterval => {
                    const isAvailable = inspector.todayAvailabilities.some(todayAvailability => {
                      return handleCompareAvailabilityInterval(
                        halfAnHourInterval,
                        todayAvailability.start_time,
                        todayAvailability.end_time
                      );
                    });

                    if (isAvailable) {
                      return <div key={halfAnHourInterval} className={classes.availableInterval} />;
                    }

                    return <div key={halfAnHourInterval} className={classes.unavailableInterval} />;
                  })}

                {orders &&
                  orders
                    .filter(order => order.schedule.who.inspector_id === inspector.public_id)
                    .map(order => (
                      <OrderCard
                        key={order?.order?.public_id}
                        order={order}
                        startOffset={getOffsetByTime(order.schedule.when.start_time)}
                        endOffset={getOffsetByTime(order.schedule.when.end_time)}
                        inspector={inspector}
                        forwardedRef={orderRef}
                        inTimeline
                      />
                    ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

ContentComponent.propTypes = {
  elementRef: PropTypes.object.isRequired,
  orderRef: PropTypes.object.isRequired,
  busy: PropTypes.bool.isRequired,
  inspectors: PropTypes.array,
  orders: PropTypes.array,
  setHoursRef: PropTypes.func.isRequired,
  getOffsetByTime: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  selectedDateIsNotToday: PropTypes.bool.isRequired,
  handleCompareAvailabilityInterval: PropTypes.func.isRequired,
};

ContentComponent.defaultProps = {
  inspectors: null,
  orders: null,
};

export default ContentComponent;
