import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AdvancedOptions from 'src/components/AdvancedOptions';
import copyIcon from 'src/components/common/icons/copy.svg';
import OrderIcon from 'src/components/OrderIcon/OrderIcon';
import ReportHistory from 'src/components/ReportHistory';
import { useUser } from 'src/contexts/UserContext';
import AccessInformationSection from 'src/orderDetails/AccessInformationSection';
import AccompaniedInspectionSection from 'src/orderDetails/AccompaniedInspectionSection';
import ActivityLog from 'src/orderDetails/ActivityLog';
import AdditionalCostsSection from 'src/orderDetails/AdditionalCostsSection';
import AdditionalsSection from 'src/orderDetails/AdditionalsSection';
import AddressSection from 'src/orderDetails/AddressSection';
import AnnotationSection from 'src/orderDetails/AnnotationSection';
import Attachments from 'src/orderDetails/Attachments';
import BudgetDuringInspectionSection from 'src/orderDetails/BudgetDuringInspectionSection';
import CancelSection from 'src/orderDetails/CancelSection';
import ClientSection from 'src/orderDetails/ClientSection';
import ConstructionDetailsSection from 'src/orderDetails/ConstructionDetailsSection';
import CostSection from 'src/orderDetails/CostSection';
import DesiredPropertySection from 'src/orderDetails/DesiredPropertySection';
import DetailsSection from 'src/orderDetails/DetailsSection';
import FichaCertaSection from 'src/orderDetails/FichaCertaSection';
import FilesToSignSection from 'src/orderDetails/FilesToSignSection';
import FranchiseeSection from 'src/orderDetails/FranchiseeSection';
import Inspections from 'src/orderDetails/Inspections';
import Inspectors from 'src/orderDetails/Inspectors';
import InterestedPersonSection from 'src/orderDetails/InterestedPersonSection';
import KeysSection from 'src/orderDetails/KeysSection';
import LocationReferencesSection from 'src/orderDetails/LocationReferencesSection';
import MediasSection from 'src/orderDetails/MediasSection';
import PartiesInvolvedSection from 'src/orderDetails/PartiesInvolvedSection';
import PhotoBuildingDetailsSection from 'src/orderDetails/PhotoBuildingDetailsSection';
import PotentialScheduleSection from 'src/orderDetails/PotentialScheduleSection';
import PreviousInspectionSection from 'src/orderDetails/PreviousInspectionSection';
import PropertyAccessSection from 'src/orderDetails/PropertyAccessSection';
import PropertyInformationsSection from 'src/orderDetails/PropertyInformationsSection';
import RecommendationSection from 'src/orderDetails/RecommendationSection';
import ResourcesSection from 'src/orderDetails/ResourcesSection';
import SignersSection from 'src/orderDetails/SignersSection';
import SolicitationsSection from 'src/orderDetails/SolicitationsSection';
import TagSection from 'src/orderDetails/TagSection';
import VacationNoticeSection from 'src/orderDetails/VacationNoticeSection';
import ViewResourcesSection from 'src/orderDetails/ViewResourcesSection';
import { showToast } from 'src/store/ducks/toasts';

const styles = {
  header: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 'calc(6px + 0.4vw)',
    marginTop: '0.6vw',
    marginBottom: '0.6vw',
  },
  subHeader: {
    fontFamily: 'Open Sans',
    color: '#858585',
    fontSize: 'calc(6px + 0.4vw)',
    fontWeight: 'bold',
  },
  advancedOptionsWrapper: {
    textAlign: 'right',
    lineHeight: 0,
  },
  alignRight: {
    textAlign: 'right',
  },
  information: {
    fontFamily: 'Open Sans',
    color: '#858585',
    fontSize: 'calc(6px + 0.4vw)',
    marginTop: '1vw',
    marginBottom: '1vw',
    paddingLeft: 'calc(14px + 1.8vw)',
  },
  container: {
    alignItems: 'center',
  },
  border: {
    fontFamily: 'Open Sans',
    color: '#ffffff',
    fontSize: 'calc(6px + 0.3vw)',
    backgroundColor: '#858585',
    borderRadius: '8vw',
    padding: '0.3vh 0.5vw',
  },
  icon: {
    fontSize: 'calc(14px + 0.6vw)',
    verticalAlign: 'middle',
    marginRight: '1vw',
  },
  marginTop: {
    marginTop: '2vh',
  },
  hr: {
    display: 'block',
    border: 0,
    borderTop: '1px solid #E9E9E9',
    padding: 0,
  },
  part: {
    fontWeight: 'bold',
    fontSize: 'calc(6px + 0.4vw)',
  },
  minHeight: {
    minHeight: '60vh',
  },
  linkCopy: {
    fontWeight: '400',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
};

const OrderDetailsBody = ({ actionsMenuGeneratePDF, classes, currentPage, data, refreshHandler, updateOrderItem }) => {
  const user = useUser();
  const dispatch = useDispatch();

  const orderFranchisee = data.form?.franchisees?.franchisee_id;
  const isFromTheSameFranchisee = user.franchiseeId === orderFranchisee;

  const canShowInspectors =
    !data.type?.includes('marketplace') &&
    user?.authRole === 'ROLE_FRANCHISEE' &&
    data.inspectors !== undefined &&
    (['FINISHED', 'CANCELED', 'VNR', 'FINALIZED_BY_DEVICE'].includes(data?.status) || !!data.inspectors?.length);

  const canShowAnnotation = user?.authRole === 'ROLE_FRANCHISEE' && isFromTheSameFranchisee;

  const copyToClipboard = async url => {
    await navigator.clipboard.writeText(url);
    dispatch(
      showToast({
        type: 'success',
        title: 'Sucesso!',
        text: 'Link copiado.',
        duration: 5000,
      })
    );
  };

  return (
    <Grid container classes={{ container: classes.container }}>
      <Grid item xs={12}>
        <hr style={styles.hr} />
      </Grid>

      <Grid container classes={{ container: classes.minHeight }}>
        <Grid item xs={6}>
          <div style={styles.subHeader} data-cy="order-details-type">
            <FormattedMessage id={data.form.inspection_type || data.type} />

            {data?.hotsite_url && (
              <span style={styles.linkCopy}>
                &nbsp; - Abrir no &nbsp;
                <a href={data.hotsite_url} target="_blank" rel="noopener noreferrer">
                  Meu Imóvel Digital
                </a>
                &nbsp; &nbsp;
                <img
                  style={styles.cursorPointer}
                  src={copyIcon}
                  alt="Copiar"
                  title="Copiar link"
                  onClick={() => copyToClipboard(data.hotsite_url)}
                />
                &nbsp; &nbsp;
              </span>
            )}

            {data?.messages?.cancel ? ' (Pedido cancelado)' : null}
          </div>
        </Grid>

        <Grid item xs={6}>
          <div style={styles.advancedOptionsWrapper}>
            <AdvancedOptions
              orderFranchisee={orderFranchisee}
              orderId={data.id}
              orderType={data.form.inspection_type || data.type}
              orderStatus={data.status}
              refreshHandler={refreshHandler}
              updateOrderItem={updateOrderItem}
            />
          </div>
        </Grid>

        {data?.responsible && (
          <Grid item xs={10}>
            <div style={styles.subHeader} data-cy="order-details-responsible">
              <FormattedMessage id="ORDER_RESPONSIBLE" />: {data?.responsible?.name} ({data?.responsible?.email}) {data?.responsible?.phone && ` - ${data?.responsible?.phone}`}
            </div>
          </Grid>
        )}
        {data.form.tags && <TagSection tags={data.form.tags} />}
        {data?.messages?.cancel && <CancelSection order={data} />}
        {data.form.clients && <ClientSection creator={data.creator} clients={data.form.clients} />}
        {data.form.franchisees && <FranchiseeSection franchisees={data.form.franchisees} />}
        {data.form.documents && <FilesToSignSection documents={data.form.documents} />}
        {data.form.details && <DetailsSection details={data.form.details} />}
        {data.form.additionalCosts?.price && <AdditionalCostsSection additionalCosts={data.form.additionalCosts} />}
        {data.form.construction_details && <ConstructionDetailsSection data={data.form.construction_details} />}
        {data.form.solicitations && <SolicitationsSection data={data.form.solicitations} />}
        {data.form.address && <AddressSection address={data.form.address} />}
        {data.form.desired_property && <DesiredPropertySection property={data.form.desired_property} />}
        {(data?.resources || []).length > 0 && <ViewResourcesSection resources={data.resources} />}
        {data.form.location_references && (
          <LocationReferencesSection locationReferences={data.form.location_references} />
        )}
        {data.form.parties_involved && <PartiesInvolvedSection partiesInvolved={data.form.parties_involved} />}
        {data.form.keys && <KeysSection keys={data.form.keys} />}
        {data.form.cost && <CostSection cost={data.form.cost} />}
        {data.form.additionals && <AdditionalsSection additionals={data.form.additionals} />}
        {data.form.accessInformation && <AccessInformationSection data={data.form.accessInformation} />}
        {data.form.budgetDuringInspection && <BudgetDuringInspectionSection data={data.form.budgetDuringInspection} />}
        {data.form.accompaniedInspection && <AccompaniedInspectionSection data={data.form.accompaniedInspection} />}
        {data.form.vacationNotice && <VacationNoticeSection data={data.form.vacationNotice} />}
        {data.form.propertyInformations && <PropertyInformationsSection data={data.form.propertyInformations} />}
        {data.form.photoBuildingDetails && <PhotoBuildingDetailsSection data={data.form.photoBuildingDetails} />}
        {data.form.propertyAccess && <PropertyAccessSection data={data.form.propertyAccess} />}
        {data.form.interestedPerson && <InterestedPersonSection data={data.form.interestedPerson} />}
        {data.form.potentialSchedule && <PotentialScheduleSection data={data.form.potentialSchedule} />}
        {data.form.recommendation && data.form.recommendation.code && (
          <RecommendationSection data={data.form.recommendation} />
        )}
        {data.form.previous_inspection && (
          <PreviousInspectionSection previousInspection={data.form.previous_inspection} orderId={data.id} />
        )}
        {data.form.recipients && <SignersSection signers={data.form.recipients} />}

        {currentPage === 'active' && data.inspections && (
          <Inspections
            orderId={data.id}
            inspections={data.inspections}
            isFromTheSameFranchisee={isFromTheSameFranchisee}
            updateOrderItem={updateOrderItem}
            refreshHandler={refreshHandler}
            actionsMenuGeneratePDF={actionsMenuGeneratePDF}
          />
        )}

        {data.form.completed_at && <ResourcesSection form={data.form} />}
        {data.type && data.type.includes('marketplace') ? null : (
          <Attachments
            data={data.form.attachments}
            orderId={data.id}
            canAdd={currentPage === 'sent' || data?.status === 'FINISHED'}
          />
        )}
        {!!data?.medias?.length && <MediasSection medias={data.medias} />}
        {data.metadata?.fichacerta?.parecer && <FichaCertaSection data={data.metadata.fichacerta.parecer} />}
        {data.reports && <ReportHistory data={data.reports} />}

        <ActivityLog orderId={data.id} />

        {canShowInspectors && (
          <Inspectors
            inspectors={data.inspectors}
            canAdd={['FINISHED', 'VNR', 'FINALIZED_BY_DEVICE'].includes(data?.status)}
            orderId={data.id}
            refreshHandler={refreshHandler}
          />
        )}

        {canShowAnnotation && (
          <Grid item xs={12}>
            <AnnotationSection
              annotation={data.annotation || {}}
              orderId={data.id}
              orderCode={data.code}
              refreshHandler={refreshHandler}
              inspectionIcon={data.type && <OrderIcon orderType={data.type} fontSize="calc(16px + 0.8vw)" />}
              orderPage={true}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

OrderDetailsBody.propTypes = {
  actionsMenuGeneratePDF: PropTypes.func,
  classes: PropTypes.object.isRequired,
  currentPage: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  updateOrderItem: PropTypes.func,
};

OrderDetailsBody.defaultProps = {
  actionsMenuGeneratePDF: null,
  updateOrderItem: null,
};

export default withStyles(styles)(OrderDetailsBody);
