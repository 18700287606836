import React from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

const OrderKeysLocationComponent = ({ keysLocationInformation }) => {
  const intl = useIntl();

  const address = keysLocationInformation?.address;
  const observation = keysLocationInformation?.keysLocation;
  const type = keysLocationInformation?.type;

  if (type === 'Outro' || type === 'Na imobiliária') {
    return (
      <>
        {address?.street}, {address?.number}, {address?.neighborhood}, {address?.city}/{address?.state}
        {observation && (
          <>
            {' '}
            - {intl.formatMessage({ id: 'keysLocation' })}: {observation}
          </>
        )}
      </>
    );
  }

  if (type === 'No local') {
    return intl.formatMessage({ id: type });
  }

  return observation;
};

OrderKeysLocationComponent.propTypes = {
  keysLocationInformation: PropTypes.object.isRequired,
};

export default OrderKeysLocationComponent;
