import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { format, isWithinInterval, isEqual, isValid } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import API from 'src/apiRequest';
import OrderFormComponent from 'src/components/SchedulePage/OrderModal/OrderForm/OrderFormComponent';
import {
  addOrUpdateOrder,
  clearFilteredInspectorsNotifications,
  deleteFilteredPendingOrder,
  setSelectedOrder,
  setUnsaved,
} from 'src/store/ducks/schedule';
import { showToast } from 'src/store/ducks/toasts';

const OrderFormContainer = ({ order, handleEmitOrderEditingHasBeenFinished, ...props }) => {
  const [warningInspectorMessage, setWarningInspectorMessage] = useState('');
  const [warningAvailabilityMessage, setWarningAvailabilityMessage] = useState('');
  const [scheduleDateAlert, setScheduleDateAlert] = useState(false);

  const dispatch = useDispatch();
  const { inspectorOptions, orders } = useSelector(({ schedule }) => ({
    inspectors: schedule?.inspectors,
    inspectorOptions: schedule?.filteredInspectors?.map(inspector => ({
      value: inspector.public_id,
      label: inspector.name,
      disabled: inspector?.status && inspector.status !== 'OK',
      status: inspector?.status,
      pendingOrderBasedMessage: inspector?.pendingOrderBasedMessage,
      todayAvailabilities: inspector?.todayAvailabilities,
    })),
    orders: schedule.orders,
  }));

  const intl = useIntl();

  const handleValidateInspectorAvailability = useCallback(
    (orderStartTime, orderEndTime) => {
      const inspector = inspectorOptions.find(inspectorOption => {
        return inspectorOption?.value === order?.schedule?.who?.inspector_id;
      });

      if (
        inspector &&
        Array.isArray(inspector.todayAvailabilities) &&
        orderStartTime &&
        orderEndTime &&
        orderStartTime.length >= 5 &&
        orderEndTime.length >= 5 &&
        !orderStartTime.includes('_') &&
        !orderEndTime.includes('_')
      ) {
        const chosenStartTimeToScheduleOrder = new Date(`1970-01-01 ${orderStartTime}`);

        const chosenEndTimeToScheduleOrder = new Date(`1970-01-01 ${orderEndTime}`);

        const isAvailable = inspector.todayAvailabilities.some(todayAvailability => {
          return (
            isWithinInterval(chosenStartTimeToScheduleOrder, {
              start: todayAvailability.start_time,
              end: todayAvailability.end_time,
            }) &&
            isWithinInterval(chosenEndTimeToScheduleOrder, {
              start: todayAvailability.start_time,
              end: todayAvailability.end_time,
            })
          );
        });

        if (!isAvailable) {
          setWarningAvailabilityMessage(intl.formatMessage({ id: 'UNAVAILABLE_INSPECTOR_ERROR_MESSAGE' }));

          return;
        }

        setWarningAvailabilityMessage('');
      }
    },
    [inspectorOptions, intl, order]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        schedule: yup.object().shape({
          who: yup.object().shape({
            inspector_id: yup.string().required(intl.formatMessage({ id: 'REQUIRED_FIELD' })),
          }),
          flextime: yup.boolean(),
          when: yup.object().when('flextime', {
            is: val => val === true,
            then: yup.object().shape({
              start_time: yup
                .string()
                .test('is-valid', intl.formatMessage({ id: 'WRONG_TIME_FORMAT' }), function checkTimeFormat(value) {
                  const date = moment(value, 'HH:mm', true);
                  return date.isValid();
                })
                .test('is-between', intl.formatMessage({ id: 'TIME_OUTSIDE_RANGE' }), function checkTimeRange(value) {
                  const startTime = moment('04:59', 'HH:mm');
                  const endTime = moment('20:00', 'HH:mm');
                  const date = moment(value, 'HH:mm');
                  return date.isBetween(startTime, endTime);
                })
                .required(intl.formatMessage({ id: 'REQUIRED_FIELD' })),
              end_time: yup.string().notRequired(),
            }),
            otherwise: yup.object().shape({
              start_time: yup
                .string()
                .test('is-valid', intl.formatMessage({ id: 'WRONG_TIME_FORMAT' }), function checkTimeFormat(value) {
                  const date = moment(value, 'HH:mm', true);
                  return date.isValid();
                })
                .test('is-between', intl.formatMessage({ id: 'TIME_OUTSIDE_RANGE' }), function checkTimeRange(value) {
                  const startTime = moment('04:59', 'HH:mm');
                  const endTime = moment('20:00', 'HH:mm');
                  const date = moment(value, 'HH:mm');
                  return date.isBetween(startTime, endTime);
                })
                .required(intl.formatMessage({ id: 'REQUIRED_FIELD' })),
              end_time: yup
                .string()
                .required(intl.formatMessage({ id: 'REQUIRED_FIELD' }))
                .test('is-valid', intl.formatMessage({ id: 'WRONG_TIME_FORMAT' }), function checkTimeFormat(value) {
                  const date = moment(value, 'HH:mm', true);
                  return date.isValid();
                })
                .test(
                  'is-greater',
                  intl.formatMessage({ id: 'MUST_BE_LONG_THAN_START_TIME' }),
                  function validateTime(value) {
                    const { start_time: startTime } = this.parent;
                    return moment(value, 'HH:mm').isAfter(moment(startTime, 'HH:mm'));
                  }
                )
                .test('is-between', intl.formatMessage({ id: 'TIME_OUTSIDE_RANGE' }), function checkTimeRange(value) {
                  const startTime = moment('04:59', 'HH:mm');
                  const endTime = moment('20:00', 'HH:mm');
                  const date = moment(value, 'HH:mm');
                  return date.isBetween(startTime, endTime);
                }),
            }),
          }),
        }),
      }),
    [intl]
  );

  const handleSubmit = async values => {
    let conflict = false;

    dispatch(clearFilteredInspectorsNotifications());

    orders.some(currentOrder => {
      const { date: date1, start_time: startTime1, end_time: endTime1 } = currentOrder?.schedule?.when || {};
      const { date: date2, start_time: startTime2, end_time: endTime2 } = values?.schedule?.when || {};

      const range1 = moment.range([
        moment(`${date1} ${startTime1}`, ['YYYY-MM-DD HH:mm', 'DD/MM/YYYY HH:mm']),
        moment(`${date1} ${endTime1}`, ['YYYY-MM-DD HH:mm', 'DD/MM/YYYY HH:mm']),
      ]);

      const range2 = moment.range([
        moment(`${date2} ${startTime2}`, ['YYYY-MM-DD HH:mm', 'DD/MM/YYYY HH:mm']),
        moment(`${date2} ${endTime2}`, ['YYYY-MM-DD HH:mm', 'DD/MM/YYYY HH:mm']),
      ]);

      if (
        range1.overlaps(range2) &&
        currentOrder?.schedule?.who?.inspector_id === values?.schedule?.who?.inspector_id &&
        currentOrder?.order?.public_id !== values?.order?.public_id
      ) {
        conflict = true;
        return true;
      }

      return false;
    });

    if (conflict) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'CONFLICT' }),
          text: intl.formatMessage({ id: 'ORDER_AT_SAME_TIME_INFORMED_ERROR' }),
          duration: 5000,
        })
      );
      return;
    }

    const payload = { ...values };
    payload.schedule.when.date = moment(payload?.schedule?.when?.date, 'DD/MM/YYYY').format('YYYY-MM-DD');

    if (payload.schedule.flextime) {
      let [hh, mm] = payload.schedule.when.start_time.split(':');
      hh = +hh + 1 >= 23 ? 23 : +hh + 1;
      mm = +hh + 1 >= 23 ? '59' : mm;
      payload.schedule.when.end_time = `${String(hh).padStart(2, '0')}:${mm}`;
    }

    dispatch(deleteFilteredPendingOrder(payload?.order?.public_id));
    dispatch(setSelectedOrder(null));
    dispatch(addOrUpdateOrder(payload));
    dispatch(setUnsaved(true));
    dispatch(
      showToast({
        type: 'success',
        title: intl.formatMessage({ id: 'SUCCESS' }),
        text: intl.formatMessage({
          id: 'ORDER_ASSIGNED_TO_SELECTED_INSPECTOR_SUCCESS',
        }),
        duration: 5000,
      })
    );

    handleEmitOrderEditingHasBeenFinished(payload?.order?.public_id);

    const me = JSON.parse(window.sessionStorage.getItem('me'));

    try {
      await API.post('/scheduler/sync', {
        channel: `panel_${me.panelId}`,
        event: 'SCHEDULE.ORDER_ASSIGNED',
        data: {
          order: payload?.order,
          schedule: payload?.schedule,
          sender: {
            id: me?.uniqueId,
            who: me?.name,
            when: format(new Date(), 'yyyy-MM-dd HH:mm'),
          },
        },
      });
    } catch {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'UNABLE_SAVE_CALENDAR_DATA_ERROR_MESSAGE' }),
          duration: 3000,
        })
      );
    }
  };

  const handleSetInspectorWarningMessage = useCallback(
    (inspectorItem = {}) => {
      if (inspectorItem?.status === 'DEVICE_NOT_CONNECTED') {
        setWarningInspectorMessage(intl.formatMessage({ id: 'INSPECTOR_WITHOUT_TABLET_ERROR_MESSAGE' }));

        return;
      }

      if (inspectorItem?.pendingOrderBasedMessage) {
        if (inspectorItem?.pendingOrderBasedMessage === 'ORDER_TYPE') {
          setWarningInspectorMessage(
            intl.formatMessage({
              id: 'DOES_NOT_DO_THE_TYPE_OF_INSPECTION_ERROR_MESSAGE',
            })
          );

          return;
        }

        if (inspectorItem?.pendingOrderBasedMessage === 'ADDRESS') {
          setWarningInspectorMessage(
            intl.formatMessage({
              id: 'DOES_NOT_SERVER_THE_NEIGHBORHOOD_ERROR_MESSAGE',
            })
          );

          return;
        }
      }

      setWarningInspectorMessage('');
    },
    [intl]
  );

  const handleInspectorSelectChange = useCallback(
    (selectedOption, triggeredAction, form, field) => {
      if (triggeredAction.action === 'clear') {
        setWarningInspectorMessage('');
      } else {
        handleSetInspectorWarningMessage(selectedOption);
      }

      return form.setFieldValue(field?.name, selectedOption?.value);
    },
    [handleSetInspectorWarningMessage]
  );

  const handlePreSelectInspector = useCallback(
    field => {
      const preSelectedInspector = inspectorOptions?.find(option => option?.value === field?.value || '');

      if (preSelectedInspector) {
        handleSetInspectorWarningMessage(preSelectedInspector);
      }

      return preSelectedInspector;
    },
    [inspectorOptions, handleSetInspectorWarningMessage]
  );

  function parseDateFromString(dateString) {
    const [day, month, yearHour] = dateString.split('/');
    const [year, hourMinute] = yearHour.split(' ');
    const [hour, minute] = hourMinute.split(':');
    return new Date(year, month - 1, day, hour, minute);
  }

  const handleValidateAccompaniedInspection = async when => {
    if (!order?.order?.accompanied_inspection?.scheduleDate || !when?.date || !when?.start_time) {
      setScheduleDateAlert(false);
      return;
    }

    const scheduleDate = parseDateFromString(order?.order?.accompanied_inspection?.scheduleDate);
    const date = parseDateFromString(`${when.date} ${when?.start_time}`);

    if (isValid(scheduleDate) && isValid(date) && !isEqual(scheduleDate, date)) {
      setScheduleDateAlert(true);
    } else {
      setScheduleDateAlert(false);
    }
  };

  return (
    <OrderFormComponent
      date={order?.schedule?.when?.date}
      validationSchema={validationSchema}
      inspectors={inspectorOptions}
      onSubmit={handleSubmit}
      warningInspectorMessage={warningInspectorMessage}
      handleInspectorSelectChange={handleInspectorSelectChange}
      handlePreSelectInspector={handlePreSelectInspector}
      intl={intl}
      handleValidateInspectorAvailability={handleValidateInspectorAvailability}
      handleValidateAccompaniedInspection={handleValidateAccompaniedInspection}
      warningAvailabilityMessage={warningAvailabilityMessage}
      scheduleDate={order?.order?.accompanied_inspection?.scheduleDate}
      scheduleDateAlert={scheduleDateAlert}
      {...props}
    />
  );
};

OrderFormContainer.propTypes = {
  order: PropTypes.object.isRequired,
  handleEmitOrderEditingHasBeenFinished: PropTypes.func.isRequired,
};

export default OrderFormContainer;
