import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { isWithinInterval, sub } from 'date-fns';
import moment from 'moment';

import API from 'src/apiRequest';
import ContentComponent from 'src/components/SchedulePage/Timeline/Content/ContentComponent';
import { useAsyncService } from 'src/contexts/AsyncServiceContext';
import {
  clearFilteredInspectorsNotifications,
  setOrders,
  setSelectedOrder,
  setUnsaved,
} from 'src/store/ducks/schedule';
import { showToast } from 'src/store/ducks/toasts';

let initialTimelineWrapperScrollTimer = null;

const ContentContainer = () => {
  const [busy, setBusy] = useState(true);
  const [hoursWidth, setHoursWidth] = useState(null);
  const [selectedDateIsNotToday, setSelectedDateIsNotToday] = useState(false);
  const elementRef = useRef();
  const { on, off } = useAsyncService();
  const { date, inspectors, orders, state } = useSelector(({ schedule }) => ({
    date: schedule.date,
    inspectors: schedule.filteredInspectors,
    orders: schedule.orders,
    state: schedule.state,
  }));
  const intl = useIntl();
  const dispatch = useDispatch();
  const orderRef = useRef();
  const hoursRef = useRef();

  const setHoursRef = useCallback(node => {
    if (node !== null) {
      hoursRef.current = node;
      setHoursWidth(node && (node.offsetWidth - 28) / 15); //  (/ 15) intervalo de horas reduzido para 15
    }
  }, []);

  const getOffsetByTime = useCallback(
    (time = null) => {
      const now = moment();
      let hours = now.hours();
      let minutes = now.minutes();
      let seconds = now.seconds();

      if (time) {
        [hours, minutes] = time.split(':');
        seconds = 0;
      }

      const offset = 15; // same number from css left offset
      const totalMinutes = (+minutes + (+hours - 5) * 60 + +seconds / 60) / 60; // ((+hours - 5) para corrigir a posição inicial para as 05:00h)

      return totalMinutes * hoursWidth + offset;
    },
    [hoursWidth]
  );

  const handleOrderListScroll = evt => {
    hoursRef.current.scrollLeft = evt.target.scrollLeft;
  };

  const fetchOrders = useCallback(async () => {
    try {
      setBusy(true);

      const formattedSchedulesDate = date.format('YYYY-MM-DD');

      if (!moment(formattedSchedulesDate).isValid()) {
        throw new Error('Invalid schedule date provided');
      }

      const { data } = await API.get('/scheduler/schedules', {
        params: {
          date: formattedSchedulesDate,
        },
      });
      dispatch(setOrders(data.orders));
      dispatch(setUnsaved(data.status === 'draft'));
    } catch (err) {
      console.log(err);
    } finally {
      setBusy(false);
    }
  }, [date, dispatch]);

  useEffect(() => {
    const scrollToCurrentTime = position => {
      if (elementRef.current) {
        const { parentElement } = elementRef.current;
        let isDragging = false;
        let startX = 0;
        let diffX = 0;

        const onMouseDown = evt => {
          if (evt?.target?.className === orderRef?.current?.className) {
            return;
          }

          startX = evt.clientX + parentElement.scrollLeft;
          diffX = 0;
          isDragging = true;
        };

        const onMouseMove = evt => {
          if (isDragging) {
            diffX = startX - (evt.clientX + parentElement.scrollLeft);
            parentElement.scrollLeft += diffX;
          }
        };

        const onMouseUp = () => {
          isDragging = false;
          let start = 1;
          const animate = () => {
            const step = Math.sin(start);
            if (step <= 0) {
              window.cancelAnimationFrame(animate);
            } else {
              parentElement.scrollLeft += diffX * step;
              start -= 0.02;
              window.requestAnimationFrame(animate);
            }
          };
          animate();
        };

        parentElement.addEventListener('scroll', handleOrderListScroll);
        parentElement.addEventListener('mousedown', onMouseDown);
        parentElement.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        hoursRef.current.style.width = `${parentElement.offsetWidth}px`;

        new ResizeObserver(() => {
          hoursRef.current.style.width = `${parentElement.offsetWidth}px`;
        }).observe(parentElement);

        initialTimelineWrapperScrollTimer = setTimeout(() => {
          parentElement.scrollTo({
            left: position - parentElement.offsetWidth / 3,
            behavior: 'smooth',
          });
        }, 1000);
      }
    };

    if (date.isSame(moment(), 'day')) {
      scrollToCurrentTime(getOffsetByTime());
      setSelectedDateIsNotToday(false);
    } else {
      scrollToCurrentTime(getOffsetByTime('09:00'));
      setSelectedDateIsNotToday(true);
    }

    return () => {
      clearTimeout(initialTimelineWrapperScrollTimer);
    };
  }, [busy, date, getOffsetByTime, elementRef]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    on('connect', fetchOrders);
    return () => off('connect', fetchOrders);
  }, [on, off, fetchOrders]);

  const handleDragEnter = evt => {
    evt.currentTarget.style.outline = '1px dashed #1c3b5e';
    evt.preventDefault();
    return false;
  };

  const handleDragLeave = evt => {
    evt.currentTarget.style.outline = '0';
    evt.preventDefault();
    return false;
  };

  const handleDragOver = evt => {
    evt.preventDefault();
    return false;
  };

  const handleDrop = (inspectorId, evt) => {
    evt.preventDefault();
    evt.currentTarget.style.outline = '0';
    const inspectorIndex = inspectors.findIndex(inspector => inspector.public_id === inspectorId);

    if (inspectors?.[inspectorIndex]?.status && inspectors[inspectorIndex].status !== 'OK') {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: inspectors[inspectorIndex].status }),
          duration: 5000,
        })
      );

      dispatch(clearFilteredInspectorsNotifications());

      return false;
    }

    const {
      order: { id: orderId },
      offsetX,
    } = JSON.parse(evt.dataTransfer.getData('application/json'));

    const { width, x } = evt.target.closest('div.timeline-track').getBoundingClientRect();
    const pos = width - (width + x + offsetX - evt.clientX) + 1 + hoursWidth * 5; // ( + hoursWidth * 5) para posição inicial 05:00h
    const time = pos / hoursWidth;
    const hour = String(Math.floor(time)).padStart(2, '0');
    const minute = String(Math.floor(((time % 1) * 60) / 15) * 15).padStart(2, '0');

    dispatch(
      setSelectedOrder({
        orderId,
        inspectorId,
        startTime: `${hour}:${minute}`,
      })
    );

    return false;
  };

  const handleCompareAvailabilityInterval = useCallback((halfAnHourInterval, start, end) => {
    return isWithinInterval(halfAnHourInterval, {
      start: sub(start, { seconds: 1 }),
      end: sub(end, { seconds: 1 }),
    });
  }, []);

  return (
    <ContentComponent
      busy={busy}
      elementRef={elementRef}
      orderRef={orderRef}
      inspectors={inspectors}
      orders={orders}
      state={state}
      setHoursRef={setHoursRef}
      getOffsetByTime={getOffsetByTime}
      selectedDateIsNotToday={selectedDateIsNotToday}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      handleCompareAvailabilityInterval={handleCompareAvailabilityInterval}
    />
  );
};

export default ContentContainer;
